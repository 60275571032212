import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
  return () => import(`@/components/${view}.vue`);
}

Vue.router = new VueRouter({
  hashbang: false,
  mode: 'history',
  base: __dirname,
  beforeRouteUpdate (to, next) {
    if (to.matched.some(record => record.meta.auth)) {
      if (!this.$auth.check()) {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  },
  afterEach (to) {
    document.title = to.meta.title || 'Synpse'
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: loadView('home/HomePage'),
      meta: {
        auth: true,
        title: 'Dashboard'
      }
    },
    // Admin routes
    {
      path: '/whitelisted-users',
      name: 'whitelistedUsers',
      component: loadView('whitelist/WhitelistedUsersPage'),
      meta: {
        auth: true,
        title: 'Whitelisted Users'
      }
    },   
    {
      path: '/registered-users',
      name: 'registeredUsers',
      component: loadView('usersAdmin/AllUsersPage'),
      meta: {
        auth: true,
        title: 'All Users'
      }
    },
    // Deploy to Synpse button
    {
      path: '/deploy',
      name: 'deployToSynpse',
      component: loadView('deploy/DeployButtonPage'),
      meta: {
        auth: true,
        title: 'Deploy To Synpse'
      }
    },
    // Projects
    {
      path: '/projects/:project',
      name: 'projectDetails',
      component: loadView('projects/ProjectDetailsPage'),
      meta: {
        auth: true,
        title: 'Project Details'
      }
    },
    // Apps
    {
      path: '/:project/:namespace/n/applications/new',
      name: 'newApplication',
      component: loadView('applications/ApplicationNew'),
      meta: {
        auth: true,
        title: 'New Application'
      }
    },
    {
      path: '/:project/:namespace/n/applications/:application',
      name: 'applicationDetails',
      component: loadView('applications/ApplicationDetailsPage'),
      meta: {
        auth: true,
        title: 'Application Details'
      }
    },
    {
      path: '/:project/:namespace/applications/:application/allocations',
      name: 'applicationAllocations',
      component: loadView('applications/ApplicationAllocations'),
      meta: {
        auth: true,
        title: 'Application Allocations'
      }
    },
    {
      path: '/:project/:namespace/applications/:application/logs/:device',
      name: 'applicationLogs',
      component: loadView('applications/ApplicationLogs'),
      meta: {
        auth: true,
        title: 'Application Logs'
      }
    },
    {
      path: '/:project/devices',
      name: 'devices',
      component: loadView('devices/DevicesPage'),
      meta: {
        auth: true,
        title: 'Devices'
      }
    },
    {
      path: '/:project/devices/:device',
      name: 'deviceDetails',
      component: loadView('devices/DeviceDetailsPage'),
      meta: {
        auth: true,
        title: 'Device Details'
      }
    },
    {
      path: '/:project/devices/ssh/:device',
      name: 'deviceSSH',
      component: loadView('devices/DeviceSSHPage'),
      meta: {
        auth: true,
        title: 'SSH'
      }
    },
    {
      path: '/:project/provisioning',
      name: 'provisioning',
      component: loadView('devices/ProvisioningPage'),
      meta: {
        auth: true,
        title: 'Provisioning'
      }
    },
    // This needs to be quite at the bottom
    // as this will be matching /project/<nothing> too
    {
      path: '/:project/:namespace/n/applications',
      alias: '/:project/:namespace/n',
      name: 'applications',
      component: loadView('applications/ApplicationsPage'),
      meta: {
        auth: true,
        title: 'Applications'
      }
    },
    {
      path: '/:project/:namespace/n/secrets',
      name: 'secrets',
      component: loadView('secrets/SecretsPage'),
      meta: {
        auth: true,
        title: 'Application Secrets'
      }
    },
    {
      path: '/access-keys',
      name: 'accessKeys',
      component: loadView('iam/UserAccessKeysPage'),
      meta: {
        auth: true,
        title: 'Access Keys'
      }
    },
    {
      path: '/ssh-keys',
      name: 'sskKeys',
      component: loadView('iam/UserSSHKeysPage'),
      meta: {
        auth: true,
        title: 'SSH Keys'
      }
    },
    {
      path: '/service-accounts',
      name: 'serviceAccounts',
      component: loadView('iam/ServiceAccountsPage'),
      meta: {
        auth: true,
        title: 'Service Accounts'
      }
    },  
    {
      path: '/login',
      name: 'login',
      component: loadView('auth/LoginPage'),
      meta: {
        auth: false,
        title: 'Login'
      }
    },
    {
      path: '/oauth/:provider',
      name: 'oauthCallback',
      component: loadView('auth/OAuthCallbackPage'),
      meta: {
        auth: false,
        title: 'OAuth'
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: loadView('auth/LogoutPage'),
      meta: {
        auth: true,
        title: 'Logout'
      }
    }
  ]
})

export default Vue.router
