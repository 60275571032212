var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","dark":"","dense":"","clipped-left":"","fixed":""}},[_c('v-app-bar-nav-icon',{staticClass:"hidden-lg-and-up",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('VUETIFY_DRAWER', !_vm.$store.state.user.drawer.isActive)}}}),_c('v-avatar',{staticClass:"mr-3",attrs:{"rounded":"","size":"28"}},[_c('img',{attrs:{"alt":"synpse-logo","src":"/favicon-32x32.png"}})]),_c('v-toolbar-title',[_vm._v("Synpse")]),_c('v-spacer'),(_vm.currentNamespaceID)?_c('v-btn-toggle',[_c('v-btn',{attrs:{"small":"","router":"","to":{
        name: 'applications',
        params: {
          project: _vm.currentProjectID,
          namespace: _vm.currentNamespaceID,
        },
      }}},[_vm._v(" Applications ")]),_c('v-btn',{attrs:{"small":"","router":"","to":{
        name: 'secrets',
        params: {
          project: _vm.currentProjectID,
          namespace: _vm.currentNamespaceID,
        },
      }}},[_vm._v(" Secrets ")])],1):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"text--secondary",attrs:{"text":"","href":_vm.docs_href,"target":"_blank","rel":"noopener"}},[_vm._v(" Docs ")]),_c('v-btn',{staticClass:"text--secondary",attrs:{"text":""},on:{"click":_vm.openCLIHelperModal}},[_vm._v(" CLI ")]),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.cliHelperModal),callback:function ($$v) {_vm.cliHelperModal=$$v},expression:"cliHelperModal"}},[_c('install-cli-helper',{attrs:{"dialog":this.cliHelperModal},on:{"clicked":_vm.closeCLIHelperModal}})],1),_c('v-menu',{staticClass:"items",attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"v-slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text",attrs:{"icon":"","light":""}},on),[(_vm.avatarUrl)?_c('v-avatar',{attrs:{"size":"32"}},[_c('img',{attrs:{"src":_vm.avatarUrl,"alt":_vm.$store.state.user.user.name}})]):_c('v-icon',[_vm._v("settings")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.dropdown_items),function(item,i){return _c('span',{key:i},[(item.divider)?_c('v-divider'):_c('v-list-item',{key:i,attrs:{"to":!item.target ? item.href : null,"href":item.target ? item.href : null,"router":!item.target,"target":"item.target"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }