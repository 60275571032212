import axios from 'axios'

const application = {
  state: () => ({
    loading: false,
    // Secrets per namespace
    secrets: [],
    secret: null,
    createdSecret: {},
    error: null
  }),

  mutations: {
    SET_SECRET_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_SECRET_ERROR: (state, error) => {
      state.error = error
    },
    SET_SECRETS: (state, secrets) => {
      state.secrets = secrets
    },
    SET_SECRET: (state, secret) => {
      state.secret = secret
    },
    SET_CREATED_SECRET: (state, createdSecret) => {
      state.createdSecret = createdSecret
    }
  },

  actions: {
    ListSecrets (context, query) {
      context.commit('SET_SECRET_LOADING', true)
      return axios.get(`/projects/${query.projectId}/namespaces/${query.namespaceId}/secrets`)
        .then((response) => {
          context.commit('SET_SECRETS', response.data)
          context.commit('SET_SECRET_LOADING', false)
        })
        .catch((error) => context.commit('SET_SECRET_ERROR', error.response.data.error.message))
    },
    GetSecret (context, query) {
      context.commit('SET_SECRET_LOADING', true)
      return axios.get(`/projects/${query.projectId}/namespaces/${query.namespaceId}/secrets/${query.id}`)
        .then((response) => {
          context.commit('SET_SECRET', response.data)
          context.commit('SET_SECRET_LOADING', false)
        })
        .catch((error) => context.commit('SET_SECRET_ERROR', error.response.data.error.message))
    },
    CreateSecret (context, secret) {
      context.commit('SET_SECRET_ERROR', null)
      return axios.post(`/projects/${secret.projectId}/namespaces/${secret.namespaceId}/secrets`, secret)
        .then((response) => {
          context.commit('SET_CREATED_SECRET', response.data)
        })
        .catch((error) => context.commit('SET_SECRET_ERROR', error.response.data.error.message))
    },
    DeleteSecret (context, secret) {
      context.commit('SET_SECRET_ERROR', null)
      return axios.delete(`/projects/${secret.projectId}/namespaces/${secret.namespaceId}/secrets/${secret.id}`)
        .then(() => {})
        .catch((error) => context.commit('SET_SECRET_ERROR', error.response.data.error.message))
    },
    UpdateSecret (context, secret) {
      context.commit('SET_SECRET_ERROR', null)
      return axios.patch(`/projects/${secret.projectId}/namespaces/${secret.namespaceId}/secrets/${secret.id}`, secret)
      .then(() => {})
      .catch((error) => {
        context.commit('SET_SECRET_ERROR', error.response.data.error.message)
      })
    }
  }
}

export default application
