<template>
  <v-navigation-drawer
    app
    :class="'grey darken-4'"
    persistent
    clipped
    v-model="isActive"
    :mini-variant.sync="mini"
    enable-resize-watcher
    width="240"
  >
    <v-list dense>
      <template v-for="(item, i) in navigationMenu">
        <!-- main heading -->
        <v-row v-if="item.heading && i === 0 && !mini" align-center :key="i">
          <v-col>
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
        </v-row>

        <v-row v-else-if="item.heading && !mini" align-center :key="i">
          <v-col>
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
        </v-row>
        <v-divider v-else-if="item.divider" :key="i"></v-divider>

        <v-list-group
          v-else-if="item.children"
          v-model="item.model"
          no-action
          :key="i"
          class="v-list-group--default"
        >
          <template v-slot:activator>
            <v-list-item-action
              style="
                iconify {
                  color: red;
                }
              "
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <span
            v-for="(child, i) in item.children"
            :key="i"
            link
            :to="!child.target ? child.href : null"
            :href="child.target ? child.href : null"
            ripple
            :router="!child.target"
            :target="child.target"
          >
            <v-list-group :value="true" no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="child.text"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(child, i) in child.children"
                :key="i"
                link
                :to="!child.target ? child.href : null"
                :href="child.target ? child.href : null"
                ripple
                :router="!child.target"
                :target="child.target"
              >
                <v-list-item-title v-text="child.text"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
        </v-list-group>
        <span v-else :key="i">
          <v-list-item
            :to="!item.target ? item.href : null"
            :href="item.target ? item.href : null"
            ripple
            v-bind:router="!item.target"
            v-bind:target="item.target"
            v-bind:disabled="item.disabled"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </span>
      </template>
    </v-list>

    <template v-slot:append>
      <div v-if="!mini && selectedProject" class="pa-2">
        <v-list>
          <v-list-item
            inactive
          >
            <v-list-item-content>
              <v-list-item-title>
                Project '{{ selectedProject.name }}'
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div class="pa-2">
        <v-btn icon @click.stop="mini = !mini">
          <v-icon v-if="!mini">chevron_left</v-icon>
          <v-icon v-else>double_arrow</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<style lang="sass">
.v-list-group.v-list-group--default
  .v-list-group__header
    min-height: 32px
    > .v-list-item__icon
      margin-bottom: 6px
      margin-top: 6px
</style>


<script>
export default {
  data: () => ({
    mini: false,
    isActive: false
  }),

  mounted() {
    this.isActive = this.$store.state.user.drawer.isActive
  },

  watch: {
    '$store.state.user.drawer.isActive'(isActive) {
      this.isActive = isActive
    },
    'isActive'(isActive) {
      this.$store.commit('VUETIFY_DRAWER', isActive)
    }
  },

  computed: {
    license() {
      return this.$store.state.license.license
    },
    selectedProject() {
      return this.$store.getters.getSelectedProject()
    },
    selectedProjectId() {
      return this.$store.state.project.selectedProjectId
    },
    namespaces() {
      return this.$store.state.namespace.namespaces
    },
    navigationMenu() {
      // If user hasn't selected a project, returning a subset
      if (this.selectedProjectId == '') {
        return [
          { heading: 'Configuration Management' },
          { icon: 'mdi-domain', text: 'Projects', href: '/' },
          // { heading: 'Account and Authentication' },
          { divider: true },
        ]
      }

      let menuNamespaces = []

      for (const namespace of this.namespaces) {
        // console.log(color);
        menuNamespaces.push({
          icon: 'folder_open',
          text: namespace.name,
          href: `/${this.selectedProjectId}/${namespace.id}/n`
        })
      }

      let userMenu = [
        { heading: 'Configuration Management' },
        { icon: 'mdi-domain', text: 'Projects', href: '/' },
        { heading: 'Namespaces' },
        ...menuNamespaces,
        { heading: 'Device Management' },
        { icon: 'mdi-server', text: 'Devices', href: `/${this.selectedProjectId}/devices`, disabled: this.selectedProjectId ? false : true },
        { icon: 'mdi-server-plus', text: 'Provisioning', href: `/${this.selectedProjectId}/provisioning`, disabled: this.selectedProjectId ? false : true },
        { heading: 'Project Authentication' },
        // { icon: 'account_circle', text: 'Roles', href: '/roles' },
        
        { icon: 'supervisor_account', text: 'Service Accounts', href: '/service-accounts' },

        { divider: true },
      ]


      if (this.$store.state.user.user && this.$store.state.user.user.superAdmin && this.license.features.superAdmin) {
        userMenu.push({
          heading: 'System Administration'
        },
          {
            icon: 'verified_user', text: 'User Whitelist', href: '/whitelisted-users'
          },
          {
            icon: 'admin_panel_settings', text: 'Registered Users', href: '/registered-users'
          }
        )
      }

      return userMenu
    }
  }
}
</script>
