const notification = {
  state: () => ({
    notification: {
      id: 0,
      message: ''
    }
  }),

  mutations: {
    SET_NOTIFICATION: (state, message) => {
      state.notification = {
        message: message,
        id: state.notification.id++
      }
    }
  },

  actions: {
    Notify (context, message) {
      context.commit('SET_NOTIFICATION', message)      
    }
  }
}

export default notification
