<template>
  <!--
    Stop dialog close so users need to click the button to close it,
    otherwise you can generate the credentials and fail to copy them
   -->
  <v-dialog id="dialog" v-model="dialog" max-width="750px" persistent>
    <v-card>
      <v-card-title>Install CLI</v-card-title>

      <v-card-text>
        To configure synpse CLI, select project, namespace and run the given
        command.
        <v-container fluid class="mt-5">
          <v-treeview
            :items="items"
            :active.sync="active"
            hoverable
            activatable
            open-on-click
            transition
          >
            <template slot="label" slot-scope="{ item }">
              {{ item.name }}
            </template>
          </v-treeview>
          <v-divider horizontal></v-divider>
          <!-- <v-row align="center" class="mb-2 mt-4"> -->

          <markup
            language="bash"
            :code="intstallCommand"
            :inline="false"
          ></markup>
          <!-- </v-row> -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text v-on:click.native="close()" class="primary--text"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
.addbtn {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
  z-index: 1;
}
</style>

<script>
import Markup from '../helpers/Markup';

export default {
  components: {
    Markup
  },
  props: {
    dialog: Boolean
  },
  data() {
    return {
      active: [],
      items: [
        {
          id: 1,
          name: 'Windows',
          children: [
            { id: 2, name: 'x86', arch: 'x86', os: "windows" },
          ],
        },
        {
          id: 3,
          name: 'Linux',
          children: [
            { id: 4, name: 'AMD64 (x86)', arch: 'amd64', os: "linux" },
            { id: 5, name: '386', arch: '386', os: "linux" },
            { id: 6, name: 'ARM 64-bit', arch: 'aarch64', os: "linux" },
            { id: 7, name: 'ARM 32-bit', arch: 'arm', os: "linux" },
          ],
        },
        {
          id: 8,
          name: 'MacOS',
          children: [
            { id: 9, name: 'AMD64 (x86)', arch: 'amd64', os: "darwin" },
          ],
        }
      ]
    }
  },

  computed: {
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]

      for (let i = 0; i < this.items.length; i++) {
        let found = this.items[i].children.find(distro => distro.id === id)
        if (found) {
          return found
        }
      }

      return undefined
    },
    intstallCommand() {
      if (this.selected){
        if (this.selected.os == "windows" )  {
          return `iwr https://downloads.synpse.net/install-cli.ps1 -useb | iex`
        } else {
          return `curl https://downloads.synpse.net/install-cli.sh | \\\n OS=` + this.selected.os + ` \\\n ARCH=` + this.selected.arch + ` \\\n bash`
        } 
      }
      return `curl https://downloads.synpse.net/install-cli.sh | bash`
    }
  },

  methods: {
    close() {
      // closing dialog
      this.$emit('clicked', '')
    }
  }
}
</script>
