import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

const opts = {
  theme: {
    dark: true,
    themes: {
      light: {
        primary: colors.deepPurple.darken4, 
        secondary: colors.grey.darken2,
        info: colors.blue.accent2,
        warning: colors.amber.darken2,
        error: colors.red.accent3,
        success: colors.green.accent4
      },
      dark: {
        primary: colors.purple.accent2,
        secondary: colors.grey.darken2,
        info: colors.cyan.lighten4,
        warning: colors.amber.darken2,
        error: colors.red.accent3,
        success: colors.purple.accent2
      }
    }
  }
}

export default new Vuetify(opts);
