import axios from 'axios'

const admin = {
  state: () => ({
    loading: false,
    registeredUsers: [],
    whitelistedUsers: [],
    createdWhitelistedUser: {},
    error: null
  }),

  mutations: {
    SET_ADMIN_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_ADMIN_ERROR: (state, error) => {
      state.error = error
    },
    SET_REGISTERED_USERS: (state, registeredUsers) => {
      state.registeredUsers = registeredUsers
    },
    SET_WHITELISTED_USERS: (state, whitelistedUsers) => {
      state.whitelistedUsers = whitelistedUsers
    },
    SET_SELECTED_PROJECT: (state, selectedProjectId) => {
      state.selectedProjectId = selectedProjectId
    },
    SET_CREATED_WHITELISTED_USER: (state, whitelistedUser) => {
      state.createdWhitelistedUser = whitelistedUser
    }
  },

  actions: {
    ListWhitelistedUsers (context) {
      context.commit('SET_ADMIN_LOADING', true)
      return axios.get('admin/whitelisted')
        .then((response) => {
          context.commit('SET_WHITELISTED_USERS', response.data)
          context.commit('SET_ADMIN_LOADING', false)
        })
        .catch((error) => context.commit('SET_ADMIN_ERROR', error.response.data.error.message))
    },
    DeleteWhitelistedUser (context, whitelistedUser) {
      context.commit('SET_ADMIN_ERROR', null)
      return axios.delete(`admin/whitelisted/${whitelistedUser.id}`)
        .then(() => {})
        .catch((error) => context.commit('SET_ADMIN_ERROR', error.response.data.error.message))
    },
    CreateWhitelistedUser (context, whitelistedUser) {
      context.commit('SET_ADMIN_ERROR', null)
      return axios.post(`admin/whitelisted`, whitelistedUser)
        .then((response) => {
          context.commit('SET_CREATED_WHITELISTED_USER', response.data)
        })
        .catch((error) => context.commit('SET_ADMIN_ERROR', error.response.data.error.message))
    },
    ListRegisteredUsers (context) {
      context.commit('SET_ADMIN_LOADING', true)
      return axios.get('admin/users')
        .then((response) => {
          context.commit('SET_REGISTERED_USERS', response.data)
          context.commit('SET_ADMIN_LOADING', false)
        })
        .catch((error) => context.commit('SET_ADMIN_ERROR', error.response.data.error.message))
    },
    DeleteUser (context, deletingUser) {
      context.commit('SET_ADMIN_ERROR', null)
      return axios.delete(`admin/users/${deletingUser.id}`)
        .then(() => {})
        .catch((error) => context.commit('SET_ADMIN_ERROR', error.response.data.error.message))
    },
    UpdateProjectQuota (context, quota) {
      context.commit('SET_ADMIN_ERROR', null)

      let payload = {
        devices: quota.devices,
        applications: quota.applications,
        namespaces: quota.namespaces,
        secrets: quota.secrets,
      }

      return axios.put(`projects/${quota.projectId}/quota`, payload)
        .then(() => {
          // OK
        })
        .catch((error) => context.commit('SET_ADMIN_ERROR', error.response.data.error.message))
    },
  }
}

export default admin
