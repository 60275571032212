<template>
  <div class="text-center ma-2">
    <v-snackbar :timeout="timeout" bottom left v-model="snackbar">
      {{ notification.message }}
      <v-btn text class="pink--text" @click.native="snackbar = false"
        >Close</v-btn
      >
    </v-snackbar>
  </div>
</template>

<script>
export default {

  data() {
    return {
      timeout: 6000,
      snackbar: false,
      notification: {
        id: 0,
        message: ''
      }
    }
  },

  watch: {
    '$store.state.notification.notification'(notification) {
      this.notification = notification
      this.snackbar = true
    }
  }
}
</script>
