import axios from 'axios'

const iam = {
  state: () => ({
    loading: false,
    serviceAccounts: [],
    userAccessKeys: [],
    userSSHKeys: [],
    serviceAccountAccessKeys: [], // do not use this for general state. it is short term storage for edit
    createdUserAccessKey: null,
    createdUserSSHKey: null,
    createdServiceAccount: null,
    createdServiceAccountAccessKey: null,
    error: null
  }),

  getters: {
  },

  mutations: {
    SET_IAM_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_IAM_ERROR: (state, error) => {
      state.error = error
    },
    SET_USER_ACCESS_KEYS: (state, userAccessKeys) => {
      state.userAccessKeys = userAccessKeys
    },
    SET_CREATED_USER_ACCESS_KEY: (state, createdUserAccessKey) => {
      state.createdUserAccessKey = createdUserAccessKey
    },
    SET_SERVICE_ACCOUNTS: (state, serviceAccounts) => {
      state.serviceAccounts = serviceAccounts
    },
    SET_CREATED_SERVICE_ACCOUNT: (state, serviceAccount) => {
      state.createdServiceAccount = serviceAccount
    },
    SET_CREATED_SERVICE_ACCOUNT_ACCESS_KEY: (state, accessKey) => {
      state.createdServiceAccountAccessKey = accessKey
    },
    SET_SERVICE_ACCOUNT_ACCESS_KEYS: (state, accessKeys) => {
      state.serviceAccountAccessKeys = accessKeys
    },
    SET_USER_SSH_KEYS: (state, userSSHKeys) => {
      state.userSSHKeys = userSSHKeys
    },
    SET_CREATED_USER_SSH_KEY: (state, createdUserSSHKey) => {
      state.createdUserSSHKey = createdUserSSHKey
    },
  },

  actions: {
    ListUserAccessKeys (context) {
      context.commit('SET_IAM_LOADING', true)
      return axios.get(`user-access-keys`)
        .then((response) => {
          context.commit('SET_USER_ACCESS_KEYS', response.data)
          context.commit('SET_IAM_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_IAM_ERROR', error.response.data.error.message)
          context.commit('SET_IAM_LOADING', false)
        })
    },
    CleanupCreatedUserAccessKey (context) {
      context.commit('SET_CREATED_USER_ACCESS_KEY', null)
    },
    CreateUserAccessKey (context, key) {
      context.commit('SET_IAM_ERROR', null)
      return axios.post(`user-access-keys`, key)
        .then((response) => {
          context.commit('SET_CREATED_USER_ACCESS_KEY', response.data)
        })
        .catch((error) => context.commit('SET_IAM_ERROR', error.response.data.error.message))
    },
    DeleteUserAccessKey (context, key) {
      context.commit('SET_IAM_ERROR', null)
      return axios.delete(`user-access-keys/${key.id}`)
        .then(() => {})
        .catch((error) => context.commit('SET_IAM_ERROR', error.response.data.error.message))
    },
    CreateServiceAccount (context, query) {
      context.commit('SET_IAM_ERROR', null)
      return axios.post(`/projects/${query.projectId}/serviceaccounts`, query.serviceAccount)
        .then((response) => {
          context.commit('SET_CREATED_SERVICE_ACCOUNT', response.data)
        })
        .catch((error) => context.commit('SET_IAM_ERROR', error.response.data.error.message))
    },
    ListServiceAccounts (context, query) {
      context.commit('SET_IAM_LOADING', true)
      return axios.get(`/projects/${query.projectId}/serviceaccounts?full`)
        .then((response) => {
          context.commit('SET_SERVICE_ACCOUNTS', response.data)
          context.commit('SET_IAM_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_IAM_ERROR', error.response.data.error.message)
          context.commit('SET_IAM_LOADING', false)
        })
    },
    DeleteServiceAccount (context, query) {
      context.commit('SET_IAM_ERROR', null)
      return axios.delete(`/projects/${query.projectId}/serviceaccounts/${query.serviceAccountId}`)
        .then(() => {})
        .catch((error) => context.commit('SET_IAM_ERROR', error.response.data.error.message))
    },
    CleanupCreatedServiceAccount (context) {
      context.commit('SET_CREATED_SERVICE_ACCOUNT', null)
    },
    CreateServiceAccountAccessKey (context, query) {
      context.commit('SET_IAM_ERROR', null)
      return axios.post(`/projects/${query.projectId}/serviceaccounts/${query.serviceAccount.id}/serviceaccountaccesskeys`, query.serviceAccountAccessKey)
        .then((response) => {
          context.commit('SET_CREATED_SERVICE_ACCOUNT_ACCESS_KEY', response.data)
        })
        .catch((error) => context.commit('SET_IAM_ERROR', error.response.data.error.message))
    },
    CleanupCreatedServiceAccountAccessKey (context) {
      context.commit('SET_CREATED_SERVICE_ACCOUNT_ACCESS_KEY', null)
    },
    ListServiceAccountsAccessKeys (context, query) {
      context.commit('SET_IAM_LOADING', true)
      return axios.get(`/projects/${query.projectId}/serviceaccounts/${query.serviceAccountId}/serviceaccountaccesskeys`)
        .then((response) => {
          context.commit('SET_SERVICE_ACCOUNT_ACCESS_KEYS', response.data)
          context.commit('SET_IAM_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_IAM_ERROR', error.response.data.error.message)
          context.commit('SET_IAM_LOADING', false)
     })
    },
    CleanupListServiceAccountsAccessKeys (context) {
      context.commit('SET_SERVICE_ACCOUNT_ACCESS_KEYS', [])
    },
    DeleteServiceAccountsAccessKey (context, query) {
      context.commit('SET_IAM_ERROR', null)
      return axios.delete(`/projects/${query.projectId}/serviceaccounts/${query.serviceAccountId}/serviceaccountaccesskeys/${query.accessKeyId}`)
        .then(() => {})
        .catch((error) => context.commit('SET_IAM_ERROR', error.response.data.error.message))
    },
    // User SSH keys
    ListUserSSHKeys (context) {
      context.commit('SET_IAM_LOADING', true)
      return axios.get(`user-ssh-keys`)
        .then((response) => {
          context.commit('SET_USER_SSH_KEYS', response.data)
          context.commit('SET_IAM_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_IAM_ERROR', error.response.data.error.message)
          context.commit('SET_IAM_LOADING', false)
        })
    },
    CleanupCreatedUserSSHKey (context) {
      context.commit('SET_CREATED_USER_SSH_KEY', null)
    },
    CreateUserSSHKey (context, key) {
      context.commit('SET_IAM_ERROR', null)
      return axios.post(`user-ssh-keys`, key)
        .then((response) => {
          context.commit('SET_CREATED_USER_SSH_KEY', response.data)
        })
        .catch((error) => context.commit('SET_IAM_ERROR', error.response.data.error.message))
    },
    DeleteUserSSHKey (context, key) {
      context.commit('SET_IAM_ERROR', null)
      return axios.delete(`user-ssh-keys/${key.id}`)
        .then(() => {})
        .catch((error) => context.commit('SET_IAM_ERROR', error.response.data.error.message))
    },
  }
}

export default iam
