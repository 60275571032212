import axios from 'axios'

const membership = {
  state: () => ({
    loading: false,
    projectMemberships: [],
    createdMembership: null,
    error: null
  }),

  getters: {
  },

  mutations: {
    SET_MEMBERSHIPS_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_MEMBERSHIPS_ERROR: (state, error) => {
      state.error = error
    },
    SET_CREATED_MEMBERSHIP: (state, createdMembership) => {
      state.createdMembership = createdMembership
    },
    SET_MEMBERSHIPS: (state, projectMemberships) => {
      state.projectMemberships = projectMemberships
    },
  },

  actions: {
    ListMembershipsByProject (context, query) {
      context.commit('SET_MEMBERSHIPS_LOADING', true)
      return axios.get(`/projects/${query.projectId}/memberships?full`)
        .then((response) => {
          context.commit('SET_MEMBERSHIPS', response.data)
          context.commit('SET_MEMBERSHIPS_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_MEMBERSHIPS_ERROR', error.response.data.error.message)
        })
    },
    CreateProjectMembership (context, payload) {
      context.commit('SET_MEMBERSHIPS_ERROR', null)
      context.commit('SET_MEMBERSHIPS_LOADING', true)
      return axios.post(`/projects/${payload.projectId}/memberships`, payload)
        .then((response) => {
          context.commit('SET_CREATED_MEMBERSHIP', response.data)
          context.commit('SET_MEMBERSHIPS_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_MEMBERSHIPS_ERROR', error.response.data.error.message)
          context.commit('SET_MEMBERSHIPS_LOADING', false)
        })
    },
    DeleteProjectMembership (context, payload) {
      context.commit('SET_MEMBERSHIPS_ERROR', null)
      context.commit('SET_MEMBERSHIPS_LOADING', true)
      return axios.delete(`/projects/${payload.projectId}/memberships/${payload.userId}`)
        .then(() => {
          context.commit('SET_MEMBERSHIPS_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_MEMBERSHIPS_ERROR', error.response.data.error.message)
          context.commit('SET_MEMBERSHIPS_LOADING', false)
        })
    }
  }
}

export default membership
