import axios from 'axios'

const user = {
  state: () => ({
    user: null,
    drawer: {
      isActive: true,
    }
  }),

  mutations: {
    'VUETIFY_DRAWER' (state, payload) {
      // Vue.ls.set('SIDEBAR', payload)
      state.drawer.isActive = payload
    },
    SET_USER_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_USER_ERROR: (state, error) => {
      state.error = error
    },
    SET_USER: (state, user) => {
      state.user = user
    },
  },

  actions: {
    GetUser (context) {
      context.commit('SET_USER_LOADING', true)
      return axios.get(`/auth/user`)
        .then((response) => {
          context.commit('SET_USER', response.data)
          context.commit('SET_USER_LOADING', false)
        })
        .catch((error) => context.commit('SET_USER_ERROR', error.response.data.error.message))
    },
  }

}

export default user
