import Vue from 'vue'
import Vuex from 'vuex'

import user from '@/store/modules/user'
import notification from '@/store/modules/notification'
import namespace from '@/store/modules/namespace'
import project from '@/store/modules/project'
import device from '@/store/modules/device'
import application from '@/store/modules/application'
import secret from '@/store/modules/secret'
import iam from '@/store/modules/iam'
import role from '@/store/modules/role'
import membership from '@/store/modules/membership'
import auth from '@/store/modules/auth'
import admin from '@/store/modules/admin'
import sse from '@/store/modules/sse'
import license from '@/store/modules/license'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    license,
    sse,
    admin,
    user,
    notification,
    auth,
    project,
    device,
    role,
    membership,
    namespace,
    application,
    secret,
    iam
  },

  getters: {
    jwt() {
      return localStorage.getItem("auth_token_default") || ''
    },
    getApplicationsForNamespace: (state) => (namespaceId) => {

      if (namespaceId in state.application.applications) {
        // already loaded
        return state.application.applications[namespaceId]
      }
        
      return []
    }
  },

  strict: debug
})