import Vue from 'vue'
import axios from "axios"

const device = {
  state: () => ({
    loading: false,
    // Available device versions
    versions: [],
    device: {},
    deviceMetrics: [],
    // Registered devices
    devices: [],
    devicesMetrics: {},
    devicesPagination: {
      pageSize: 5,
      totalItems: 0,
      nextPageToken: '',
      previousPageToken: '',
      currentPageToken: ''
    },
    // Device registration tokens
    registrationToken: {}, // details
    universalRegistrationToken: {},
    registrationTokens: [],
    createdDeviceRegistrationToken: {},
    error: null,
  }),

  getters: {},

  mutations: {
    SET_DEVICE_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_DEVICE_ERROR: (state, error) => {
      state.error = error
    },
    SET_DEVICE_VERSIONS: (state, versions) => {
      state.versions = versions
    },
    SET_DEVICES: (state, devices) => {
      state.devices = devices
    },
    SET_DEVICES_PAGINATION_PAGE_SIZE: (state, pageSize) => {
      state.devicesPagination.pageSize = pageSize
      // Also resetting the pagination token
      // as it's not valid for certain situations
      state.devicesPagination.nextPageToken = ''
      state.devicesPagination.previousPageToken = ''
    },
    SET_DEVICES_PAGINATION_CURRENT_PAGE_TOKEN: (state, currentPageToken) => {
      state.devicesPagination.currentPageToken = currentPageToken
    },
    SET_DEVICES_PAGINATION: (state, headers) => {
      if ('previous-page-token' in headers) {
        state.devicesPagination.previousPageToken = headers['previous-page-token']
      } else {
        state.devicesPagination.previousPageToken = ''
      }

      if ('next-page-token' in headers) {
        state.devicesPagination.nextPageToken = headers['next-page-token']
      } else {
        state.devicesPagination.nextPageToken = ''
      }
      state.devicesPagination.totalItems = parseInt(headers['total-items'])
    },
    SET_DEVICES_METRICS: (state, devicesMetrics) => {
      // set device metrics will calculate and set the metrics for the devices as a unique hash keys values
      for (var key in devicesMetrics.results) {
        let result = {
          device: key,
          // TODO(mj): 0.13+ clean this
          memPercent: 0,
          cpuPercent:0,
          memTotal: 0,
          // old metrics below
          memFree : 0,
          memUsed: 0,
          memUsedPercent: 0,
          cpuUtilisation: 0,
        }

        let metrics = devicesMetrics.results[key]

        if(metrics.length == 0){
          continue
        }

        latestMetric = metrics[0]

        if (metrics.length > 0){
          var latestMetric = metrics[0]
          for (var idx in metrics){
            const latest = new Date(latestMetric.timestamp).valueOf()
            const current = new Date(metrics[idx].timestamp).valueOf()
            if (current > latest){
              latestMetric = metrics[idx]
            }
          }
          // TODO: 0.13+ new metrics
          //result.cpuPercent = latestMetric.cpuTotalPercentageAvg

          // new format RAM
          if (latestMetric.memUsedPercentAvg  !== 0 ){
            result.memUsedPercent =  latestMetric.memUsedPercentAvg
            result.memTotal = Math.round((latestMetric.memTotalAvg / 1024 / 1024 / 1024))
            result.memUsed = Math.round((result.memTotal * result.memUsedPercent) / 100)
            result.memFree = result.memTotal - result.memUsed
            
          // old format
          } else{
            result.memFree  = Math.round(latestMetric.memFreeAvg / 1024 / 1024 / 1024 )
            result.memUsed = Math.round(latestMetric.memUsedAvg / 1024 / 1024 / 1024)
            if (latestMetric.memUsedAvg !== 0 && latestMetric.memTotalAvg !== 0){
              result.memUsedPercent = Math.round((latestMetric.memUsedAvg *100)/(latestMetric.memTotalAvg))
            } else {
              result.memUsedPercent = 0
            }
            
            result.memTotal = Math.round((latestMetric.memTotalAvg / 1024 / 1024 / 1024))
          }

          // new format CPU
          if (latestMetric.cpuTotalPercentageAvg !== 0){
            result.cpuUtilisation = Math.round(latestMetric.cpuTotalPercentageAvg)
          } else {
            // old metric
            if (latestMetric.cpuIdleAvg !== 0){
            result.cpuUtilisation =  Math.round(100 - latestMetric.cpuIdleAvg)
            } else {
              result.cpuUtilisation = 0
            }
          }

          state.devicesMetrics[result.device] = result
        }
      }
    },
    SET_DEVICE: (state, device) => {
      state.device = device
    },
    SET_DEVICE_METRICS: (state, metrics) => {
      for (var idx in metrics){
        // CPU
        // TODO: 0.13+ clean this if nightmare
        if (metrics[idx].cpuTotalPercentageAvg !== 0){
          //console.log("use new cpu metrics format")
          metrics[idx].cpuPercent = metrics[idx].cpuTotalPercentageAvg
          metrics[idx]['cpuUtilisation'] = Math.round(metrics[idx].cpuTotalPercentageAvg)
        } else{
          // old statement
         // console.log("use old cpu metrics format")
          if (metrics[idx].cpuIdleAvg !== 0) {
            metrics[idx]['cpuUtilisation'] = Math.round(100 - metrics[idx].cpuIdleAvg)
          } else {
            metrics[idx]['cpuUtilisation'] = 0
          }
        }

        // RAM
        if (metrics[idx].memUsedPercentAvg !== 0){
         // console.log("use new mem metrics format")
          metrics[idx].memPercent = metrics[idx].memUsedPercentAvg 
          metrics[idx].memTotal = Math.round((metrics[idx].memTotalAvg / 1024 / 1024 / 1024))
          metrics[idx].memUsed = Math.round((metrics[idx].memTotal * metrics[idx].memPercent) / 100)
        } else{
          // old statement
          //console.log("use old mem metrics format")
          metrics[idx].memTotal = Math.round((metrics[idx].memTotalAvg / 1024 / 1024 / 1024))
          metrics[idx].memUsed = Math.round((metrics[idx].memUsedAvg / 1024 / 1024 / 1024))
        }

      }
      state.deviceMetrics = metrics
    },
    RESET_DEVICE_METRICS: (state) => {
      state.deviceMetrics = []
    },
    SET_DEVICE_REGISTRATION_TOKENS: (state, registrationTokens) => {
      state.registrationTokens = registrationTokens
    },
    SET_DEVICE_REGISTRATION_TOKEN: (state, registrationToken) => {
      state.registrationToken = registrationToken
    },
    SET_DEVICE_QUICK_REGISTRATION_TOKEN: (state, data) => {
      for (var idx in state.registrationTokens){
       // inject quick token into existing data model
        if (state.registrationTokens[idx].id === data.id){
          state.registrationTokens[idx].quickRegistrationToken = data.value
        }
      }
    },
    SET_CREATED_DEVICE_REGISTRATION_TOKEN: (
      state,
      createdDeviceRegistrationToken
    ) => {
      state.createdDeviceRegistrationToken = createdDeviceRegistrationToken
    },
  },

  actions: {
    SetDevicesPageSize(context, pageSize) {
      Vue.ls.set('DEVICES_PAGE_SIZE', pageSize)
      context.commit('SET_DEVICES_PAGINATION_PAGE_SIZE', pageSize)
    },
    LoadDevicesPageSize(context) {
      let pageSize = Vue.ls.get('DEVICES_PAGE_SIZE');
      context.commit('SET_DEVICES_PAGINATION_PAGE_SIZE', pageSize)
    },
    // SetDevicesPaginationPreviousPageToken(context, previousPagePath) {
    //   context.commit('SET_DEVICES_PAGINATION_PREVIOUS_PAGE_TOKEN', previousPagePath)
    // },
    GetDeviceVersions(context, q) {
      axios.get(`projects/${q.projectId}/device-versions`)
        .then(response => {
          context.commit('SET_DEVICE_VERSIONS', response.data)
        })
        .catch(error => {
          context.commit('SET_DEVICE_ERROR', error)
        })
    },
    GetDevices(context, q) {
      context.commit("SET_DEVICE_ERROR", null)
      // Do not show loading for background refreshes
      if (!q.background) {
        context.commit("SET_DEVICE_LOADING", true)
      }
      let url =  `projects/${q.projectId}/devices?pageSize=${q.pageSize}&full`
      if (q.query !== '') {
        url += `&q=${q.query}`
      }
      if (q.pageToken !== '') {
        url = url + `&pageToken=${q.pageToken}`
      }
      return axios
        .get(url)
        .then((response) => {
          context.commit("SET_DEVICES_PAGINATION_CURRENT_PAGE_TOKEN", q.pageToken)
          context.commit("SET_DEVICES_PAGINATION", response.headers)
          context.commit("SET_DEVICES", response.data)
          context.commit("SET_DEVICE_LOADING", false)
        })
        .catch((error) =>
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        )
    },
    GetDevicesMetrics(context, q) {
      context.commit("SET_DEVICE_ERROR", null)
      // Do not show loading for background refreshes
      if (!q.background) {
        context.commit("SET_DEVICE_LOADING", true)
      }
      let url =  `projects/${q.projectId}/devices-metrics?pageSize=${q.pageSize}&window=last30Minutes`
      if (q.query !== '') {
        url += `&q=${q.query}`
      }
      if (q.pageToken !== '') {
        url = url + `&pageToken=${q.pageToken}`
      }
      return axios
        .get(url)
        .then((response) => {
          context.commit("SET_DEVICES_METRICS", response.data)
        })
        .catch((error) =>
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        )
    },
    ResetDeviceMetrics(context) {
      context.commit("RESET_DEVICE_METRICS")
    },
    GetDevice(context, q) {
      context.commit("SET_DEVICE_ERROR", null)
      context.commit("SET_DEVICE_LOADING", true)
      return axios
        .get(`projects/${q.projectId}/devices/${q.deviceId}?full`)
        .then((response) => {
          context.commit("SET_DEVICE", response.data)
          context.commit("SET_DEVICE_LOADING", false)
        })
        .catch((error) =>
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        )
    },
    GetDeviceMetrics(context, q) {
      context.commit("SET_DEVICE_ERROR", null)
      let url =  `projects/${q.projectId}/devices/${q.deviceId}/metrics`
      return axios
        .get(url)
        .then((response) => {
          context.commit("SET_DEVICE_METRICS", response.data)
        })
        .catch((error) =>
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        )
    },
    DeleteDevice(context, device) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .delete(`projects/${device.projectId}/devices/${device.id}`)
        .then(() => {})
        .catch((error) =>
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        )
    },
    UpdateDevice(context, device) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .patch(`projects/${device.projectId}/devices/${device.id}`, device)
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    RebootDevice(context, device) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .post(
          `projects/${device.projectId}/devices/${device.id}/reboot`,
          device
        )
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    WipeDevice(context, device) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .post(`projects/${device.projectId}/devices/${device.id}/wipe`, device)
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    SetDeviceLabel(context, payload) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .put(
          `projects/${payload.projectId}/devices/${payload.deviceId}/labels`,
          payload.values
        )
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    DeleteDeviceLabel(context, payload) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .delete(
          `projects/${payload.projectId}/devices/${payload.deviceId}/labels/${payload.key}`
        )
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    SetDeviceEnvironmentVariable(context, payload) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .put(
          `projects/${payload.projectId}/devices/${payload.deviceId}/environmentvariables`,
          payload.value
        )
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    UpdateDeviceTunnels(context, payload) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .patch(
          `projects/${payload.projectId}/devices/${payload.deviceId}/tunnels`,
          payload
        )
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    DeleteDeviceEnvironmentVariable(context, payload) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .delete(
          `projects/${payload.projectId}/devices/${payload.deviceId}/environmentvariables/${payload.key}`
        )
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    // Device registration tokens, used for provisioning
    GetDeviceRegistrationToken(context, q) {
      context.commit("SET_DEVICE_LOADING", true)
      return axios
        .get(`projects/${q.projectId}/device-registration-tokens/${q.id}`)
        .then((response) => {
          context.commit("SET_DEVICE_REGISTRATION_TOKEN", response.data)
          context.commit("SET_DEVICE_LOADING", false)
        })
        .catch((error) =>
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        )
    },
    ListDeviceRegistrationTokens(context, q) {
      context.commit("SET_DEVICE_LOADING", true)
      return axios
        .get(`projects/${q.projectId}/device-registration-tokens?full`)
        .then((response) => {
          context.commit("SET_DEVICE_REGISTRATION_TOKENS", response.data)
          context.commit("SET_DEVICE_LOADING", false)
        })
        .catch((error) =>
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        )
    },
    CreateDeviceRegistrationToken(context, registrationToken) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .post(
          `projects/${registrationToken.projectId}/device-registration-tokens`,
          registrationToken
        )
        .then((response) => {
          context.commit("SET_CREATED_DEVICE_REGISTRATION_TOKEN", response.data)
        })
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    DeleteDeviceRegistrationToken(context, registrationToken) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .delete(
          `projects/${registrationToken.projectId}/device-registration-tokens/${registrationToken.id}`
        )
        .then(() => {})
        .catch((error) =>
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        )
    },
    UpdateDeviceRegistrationToken(context, registrationToken) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .put(
          `projects/${registrationToken.projectId}/device-registration-tokens/${registrationToken.id}`,
          registrationToken
        )
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    SetDeviceRegistrationTokenLabel(context, payload) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .put(
          `projects/${payload.projectId}/device-registration-tokens/${payload.id}/labels`,
          payload.label
        )
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    DeleteDeviceRegistrationTokenLabel(context, payload) {
      context.commit("SET_DEVICE_ERROR", null)
      return axios
        .delete(
          `projects/${payload.projectId}/device-registration-tokens/${payload.id}/labels/${payload.key}`
        )
        .then(() => {})
        .catch((error) => {
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        })
    },
    GetDeviceRegistrationQuickToken(context, q) {
      context.commit("SET_DEVICE_LOADING", true)
      return axios
        .get(`projects/${q.projectId}/device-registration-tokens/${q.id}/quick-token`)
        .then((response) => {
          let data = {
            value: response.data.value,
            id: q.id
          }
          context.commit("SET_DEVICE_QUICK_REGISTRATION_TOKEN", data)
          context.commit("SET_DEVICE_LOADING", false)
        })
        .catch((error) =>
          context.commit("SET_DEVICE_ERROR", error.response.data.error.message)
        )
    },
  },
}

export default device
