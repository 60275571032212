import axios from 'axios'

const license = {
  state: () => ({
    loading: false,
    license: {},
    error: null
  }),

  getters: {
  },

  mutations: {
    SET_LICENSE_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_LICENSE_ERROR: (state, error) => {
      state.error = error
    },
    SET_LICENSE: (state, license) => {
      state.license = license
    },
  },

  actions: {
    GetLicense (context) {
      context.commit('SET_LICENSE_LOADING', true)
      context.commit('SET_LICENSE_ERROR', null)
      axios.get('license')
        .then(response => {
          context.commit('SET_LICENSE', response.data)
          context.commit('SET_LICENSE_LOADING', false)
        })
        .catch(error => {
          context.commit('SET_LICENSE_LOADING', false)
          context.commit('SET_LICENSE_ERROR', error)
        })
    }
  }
}

export default license