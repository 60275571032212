import Vue from 'vue'
import axios from 'axios'

const namespace = {
  state: () => ({
    loading: false,
    namespaces: [],

    selectedNamespaceId: '',
    createdNamespace: {},
    error: null
  }),

  getters: {
  },

  mutations: {
    SET_NAMESPACE_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_NAMESPACE_ERROR: (state, error) => {
      state.error = error
    },
    SET_NAMESPACES: (state, namespaces) => {
      state.namespaces = namespaces
    },
    SET_CREATED_NAMESPACE: (state, createdApplication) => {
      state.createdApplication = createdApplication
    },
    SET_SELECTED_NAMESPACE: (state, selectedNamespaceId) => {
      state.selectedNamespaceId = selectedNamespaceId
    },
  },

  actions: {
    SelectNamespace(context, id) {
      Vue.ls.set('SELECTED_NAMESPACE', id)
      context.commit('SET_SELECTED_NAMESPACE', id)
    },
    LoadSelectedNamespace(context) {
      let id = Vue.ls.get('SELECTED_NAMESPACE');
      if (id) {
        context.commit('SET_SELECTED_NAMESPACE', id)
        return
      }
      // Selecting default is there is one with the name 'default'
      let defaultNamespace = context.state.namespaces.find(namespace => namespace.name === 'default')
      if (defaultNamespace) {
        context.commit('SET_SELECTED_NAMESPACE', defaultNamespace.id)
        return
      }
      // Selecting one if there are any
      if (context.state.namespaces.length > 0) {
        context.commit('SET_SELECTED_NAMESPACE', context.state.namespaces[0].id)
        return
      }
    },
    ListNamespaces (context, query) {
      if (query.project === '') {
        // Missing project ID, can't query
        return
      }
      context.commit('SET_NAMESPACE_LOADING', true)
      return axios.get(`/projects/${query.project}/namespaces`)
        .then((response) => {
          context.commit('SET_NAMESPACES', response.data)
          context.commit('SET_NAMESPACE_LOADING', false)
        })
        .catch((error) => {
          if (error.response.status === 404) {
            // Project doesn't exist anymore,
            Vue.ls.set('SELECTED_NAMESPACE', '')
            return
          }
          context.commit('SET_NAMESPACE_ERROR', error.response.data.error.message)
        })
    },
    CreateNamespace (context, namespace) {
      context.commit('SET_NAMESPACE_ERROR', null)
      return axios.post(`projects/${namespace.projectId}/namespaces`, namespace)
        .then((response) => {
          context.commit('SET_CREATED_NAMESPACE', response.body)
        })
        .catch((error) => context.commit('SET_NAMESPACE_ERROR', error.response.data.error.message))
    },
    DeleteNamespace (context, namespace) {
      context.commit('SET_NAMESPACE_ERROR', null)
      return axios.delete(`projects/${namespace.projectId}/namespaces/${namespace.id}`)
        .then(() => {})
        .catch((error) => context.commit('SET_NAMESPACE_ERROR', error.response.data.error.message))
    },
    UpdateNamespace (context, namespace) {
      context.commit('SET_NAMESPACE_ERROR', null)
      return axios.put(`projects/${namespace.project_id}/namespaces/${namespace.id}`, namespace)
      .then(() => {})
      .catch((error) => {
        context.commit('SET_NAMESPACE_ERROR', error.response.data.error.message)
      })
    }
  }
}

export default namespace
