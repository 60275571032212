import Vue from 'vue'
import moment from 'moment'

Vue.filter('date', function (value) {
  return moment(value).format('MMMM Do YYYY, h:mm:ss a')
})

Vue.filter('ymd', function (value) {
  return moment(value).format('MMMM Do YYYY')
})

Vue.filter('ago', function (value) {
  return moment(value).fromNow();
})