<template>
  <div>
    <div v-if="$auth.ready()">
      <!-- configuring sidebar only if user is authenticated -->
      <div v-if="$auth.check()">
        <v-app dark>
          <navigation-drawer></navigation-drawer>
          <toolbar></toolbar>
          <v-main>
            <v-container container--fluid class="mb-14">
              <router-view></router-view>
              <notification></notification>
            </v-container>
          </v-main>
          <v-footer padless v-if="license && !license.synpseCloud" :class="getLicenseFooterColour">
            <v-col
              class="text-center caption"
              cols="12"              
            >
              <span v-if="getLicenseDaysRemaining > 0" :class="getLicenseFooterTextColour">
                Licensed for '{{ license.organization }}' | Expires: {{ license.validUntil | ymd }} | License ID: {{ license.id }}        
              </span>
              <span v-else :class="getLicenseFooterTextColour">
                Licensed for '{{ license.organization }}' | License expired on: {{ license.validUntil | ymd }} | License ID: {{ license.id }} | Functionality is limited
              </span>
              <span v-if="getLicenseDaysRemaining < 30" :class="getLicenseFooterTextColour">
                | Contact hello@synpse.net for renewal 
              </span>
            </v-col>
          </v-footer>
        </v-app>
      </div>
      <!-- for unauthenticated users - do not add fixed sidebar -->
      <div v-else>
        <v-app>
          <router-view></router-view>
        </v-app>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationDrawer from './components/NavigationDrawer';
import Toolbar from './components/Toolbar';
import Notification from './components/Notification';

import moment from 'moment'

export default {
  name: 'App',

  components: {
    NavigationDrawer,
    Toolbar,
    Notification
  },

  data: () => ({
    //
  }),

  mounted() {
    this.initializeLicense();
    this.$auth
      .load()
      .then(() => {
        if (this.$auth.check()) {
          // Getting current user
          this.$store.dispatch('GetUser')
          // Initializing project
          this.initializeProject()
        }
      })
  },

  watch: {
    'selectedProjectId'() {
      this.loadProjecNamespaces()
    },
    '$route'(to) {
      document.title = to.meta.title || 'Synpse'
    }
  },

  computed: {
    license() {
      return this.$store.state.license.license
    },
    getLicenseDaysRemaining() {
      const eventdate = moment(this.$store.state.license.license.validUntil);
      const todaysdate = moment();
      const diffDays = eventdate.diff(todaysdate, 'days');
      return diffDays
    },
    getLicenseFooterTextColour() {
      if (!this.$store.state.license.license.validUntil) {
        return ''
      }

      const diffDays = this.getLicenseDaysRemaining 
      if (diffDays < 2) {
        return ''
      }
      if (diffDays < 30) {
        return 'black--text'
      }
      return ''
    },
    getLicenseFooterColour() {
      if (!this.$store.state.license.license.validUntil) {
        return ''
      }

      const diffDays = this.getLicenseDaysRemaining 
      if (diffDays < 2) {
        return 'red darken-3'
      }
      if (diffDays < 30) {
        return 'amber lighten-1'
      }
      return ''
    },
    selectedProjectId() {
      return this.$store.state.project.selectedProjectId
    },
    projectMemberships() {
      return this.$store.state.project.memberships
    },
    selectedNamespace() {
      return this.$store.state.namespace.selectedProjectId
    },
  },

  methods: {
    async initializeLicense() {
      await this.$store.dispatch('GetLicense')  
    },
    async initializeProject() {
      await this.$store.dispatch('GetProjectMemberships')
      this.$store.dispatch('LoadSelectedProject')
      if (!this.selectedProjectId) {
        let first = this.$store.getters.getFirstProject()
        this.$store.dispatch('SelectProject', first)
      }

      if (this.projectMemberships.length > 0) {
        await this.loadProjecNamespaces()
      }
    },
    async loadProjecNamespaces() {
      let q = {
        project: this.selectedProjectId
      }
      await this.$store.dispatch('ListNamespaces', q)
      this.$store.dispatch('LoadSelectedNamespace')
    }
  }
};
</script>
