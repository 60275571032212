import Vue from "vue"

const sse = {
  state: () => ({
    index: 0, // Incremented on each action
    streamName: "",
    conn: null,
  }),

  mutations: {
    SET_SSE_STREAM: (state, streamInfo) => {
      state.streamName = streamInfo.streamName
      state.index = streamInfo.index
    },
    SET_SSE_CONN: (state, conn) => {
      // Any previous must be closed
      if (state.conn) {
        state.conn.close()
      }
      state.conn = conn
    },
    CANCEL_SSE_CONN: (state) => {
      state.streamName = ''
      state.index++
      if (state.conn) {
        state.conn.close()
        state.conn = null
      }
    },
  },

  actions: {
    SubscribeToSSE(context, q) {
      // Cancelling any previous stream
      if (context.state.conn) {
        context.state.conn.close()
      }
      let subscriptionIndex = context.state.index + 1
      // Need to track to which we have subscribed now
      let streamInfo = {
        streamName: q.stream,
        index: subscriptionIndex,
      }
      context.commit("SET_SSE_STREAM", streamInfo)

      // We can expect a lot of events here, throttling them
      // every 3secs so we are not taking a toll on the browser/server
      let throttledCallback = throttle(q.callback, 3000)

      // Connecting to the stream
      Vue.SSE(q.stream, { format: "json" }).then((sse) => {
        if (
          context.state.conn != null ||
          q.stream !== context.state.streamName ||
          subscriptionIndex !== context.state.index
        ) {
          // Already connected in another stream
          sse.close()
          return
        }
        context.commit("SET_SSE_CONN", sse)

        sse.subscribe("", () => {
          throttledCallback()
        })
      })
    },
    UnsubscribeFromSSE(context) {
      context.commit("CANCEL_SSE_CONN")
    },
  },
}

function throttle(callback, limit) {
  var wait = false // Initially, we're not waiting
  return function() {
    // We return a throttled function
    if (!wait) {
      // If we're not waiting
      callback.call() // Execute users function
      wait = true // Prevent future invocations
      setTimeout(function() {
        // After a period of time
        wait = false // And allow future invocations
      }, limit)
    }
  }
}

export default sse
