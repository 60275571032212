import Vue from 'vue'
import axios from 'axios'

const project = {
  state: () => ({
    loading: false,
    // memberships where the user has access to
    memberships: [],
    project: null,
    selectedProjectId: null,
    createdProject: {},
    createError: null,
    error: null
  }),

  getters: {
    getFirstProject: (state) => () => {
      if (state.memberships.length > 0) {
        return state.memberships[0].projectId
      }
      return ''
    },
    getSelectedProject: (state) => () => {
      if (state.memberships.length > 0) {
        const found = state.memberships.find(membership => {
          if (membership.project.id == state.selectedProjectId) {
            return membership
          }
        })
        if (found) {
          return found.project
        }
        return null
      }
      return null
    }
  },

  mutations: {
    SET_PROJECT_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_PROJECT_ERROR: (state, error) => {
      state.error = error
    },
    SET_PROJECT_CREATE_ERROR: (state, createError) => {
      state.createError = createError
    },
    SET_PROJECT_MEMBERSHIPS: (state, memberships) => {
      state.memberships = memberships
      // TODO: sort?
      // state.projects.sort(function (x, y) {
      //   return y.created_at - x.created_at
      // })
    },
    SET_PROJECT: (state, project) => {
      state.project = project
    },
    SET_SELECTED_PROJECT: (state, selectedProjectId) => {
      state.selectedProjectId = selectedProjectId
    },
    SET_CREATED_PROJECT: (state, createdProject) => {
      state.createdProject = createdProject
    }
  },

  actions: {
    SelectProject(context, id) {
      Vue.ls.set('SELECTED_PROJECT', id)
      context.commit('SET_SELECTED_PROJECT', id)
    },
    LoadSelectedProject(context) {
      let id = Vue.ls.get('SELECTED_PROJECT');
      context.commit('SET_SELECTED_PROJECT', id)     
    },
    ClearSelectedProject(context) {
      Vue.ls.remove('SELECTED_PROJECT');
      context.commit('SET_SELECTED_PROJECT', '')
    },
    GetProjectMemberships (context) {
      context.commit('SET_PROJECT_LOADING', true)
      return axios.get('memberships?full')
        .then((response) => {
          context.commit('SET_PROJECT_MEMBERSHIPS', response.data)
          context.commit('SET_PROJECT_LOADING', false)
        })
        .catch((error) => context.commit('SET_PROJECT_ERROR', error.response.data.error.message))
    },
    GetProject (context, query) {
      context.commit('SET_PROJECT_LOADING', true)
      return axios.get(`projects/${query.projectId}?full`)
        .then((response) => {
          context.commit('SET_PROJECT', response.data)
          context.commit('SET_PROJECT_LOADING', false)
        })
        .catch((error) => context.commit('SET_PROJECT_ERROR', error.response.data.error.message))
    },
    DeleteProject (context, project) {
      context.commit('SET_PROJECT_ERROR', null)
      return axios.delete(`projects/${project.id}`)
        .then(() => {})
        .catch((error) => context.commit('SET_PROJECT_ERROR', error.response.data.error.message))
    },
    CreateProject (context, project) {
      context.commit('SET_PROJECT_CREATE_ERROR', null)
      return axios.post(`projects`, project)
        .then((response) => {
          context.commit('SET_CREATED_PROJECT', response.data)
        })
        .catch((error) => context.commit('SET_PROJECT_CREATE_ERROR', error.response.data.error.message))
    },
    ClearCreateProjectError (context) {
      context.commit('SET_PROJECT_CREATE_ERROR', null)
    },
    UpdateProject (context, token) {
      context.commit('SET_PROJECT_ERROR', null)
      return axios.put(`projects/${token.id}`, token)
      .then(() => {})
      .catch((error) => {
        context.commit('SET_PROJECT_ERROR', error.response.data.error.message)
      })
    }
  }
}

export default project
