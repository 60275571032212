import axios from 'axios'

const application = {
  state: () => ({
    loading: false,
    // Applications per namespace
    applications: [],
    applicationAllocations: [],
    application: null,
    applicationHints: [],
    createdApplication: {},
    deployTemplate: ``, // From button
    error: null
  }),

  mutations: {
    SET_APPLICATION_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_APPLICATION_ERROR: (state, error) => {
      state.error = error
    },
    SET_APPLICATIONS: (state, applications) => {
      state.applications = applications
    },
    SET_APPLICATION_ALLOCATIONS: (state, applicationAllocations) => {
      state.applicationAllocations = applicationAllocations
    },
    SET_APPLICATION: (state, application) => {
      state.application = application
    },
    SET_APPLICATION_HINTS: (state, applicationHints) => {
      state.applicationHints = applicationHints
    },
    SET_CREATED_APPLICATION: (state, createdApplication) => {
      state.createdApplication = createdApplication
    },
    SET_DEPLOY_TO_SYNPSE_APPLICATION: (state, deployTemplate) => {
      state.deployTemplate = deployTemplate
    }
  },

  actions: {
    ListApplications (context, query) {
      if (!query.background) {
        context.commit('SET_APPLICATION_LOADING', true)
      }
      return axios.get(`/projects/${query.projectId}/namespaces/${query.namespaceId}/applications`)
        .then((response) => {
          context.commit('SET_APPLICATIONS', response.data)
          context.commit('SET_APPLICATION_LOADING', false)
        })
        .catch((error) => context.commit('SET_APPLICATION_ERROR', error.response.data.error.message))
    },
    ListApplicationAllocations (context, query) {
      if (!query.background) {
        context.commit('SET_APPLICATION_ALLOCATIONS', [])
        context.commit('SET_APPLICATION_LOADING', true)
      }
      return axios.get(`/projects/${query.projectId}/namespaces/${query.namespaceId}/applications/${query.applicationId}/allocations`)
        .then((response) => {
          context.commit('SET_APPLICATION_ALLOCATIONS', response.data)
          context.commit('SET_APPLICATION_LOADING', false)
        })
        .catch((error) => context.commit('SET_APPLICATION_ERROR', error.response.data.error.message))
    },
    GetApplication (context, query) {
      if (!query.background) {
        context.commit('SET_APPLICATION_LOADING', true)
      }      
      return axios.get(`/projects/${query.projectId}/namespaces/${query.namespaceId}/applications/${query.applicationId}?full`)
        .then((response) => {
          context.commit('SET_APPLICATION', response.data)
          context.commit('SET_APPLICATION_LOADING', false)
        })
        .catch((error) => context.commit('SET_APPLICATION_ERROR', error.response.data.error.message))
    },
    GetApplicationHints (context, query) {
      if (!query.background) {
        context.commit('SET_APPLICATION_HINTS', [])
      }
      return axios.get(`/projects/${query.projectId}/namespaces/${query.namespaceId}/applications/${query.applicationId}/hints`)
        .then((response) => {
          context.commit('SET_APPLICATION_HINTS', response.data)      
        })
        .catch((error) => context.commit('SET_APPLICATION_ERROR', error.response.data.error.message))
    },
    CreateApplication (context, app) {
      context.commit('SET_APPLICATION_ERROR', null)
      return axios.post(`/projects/${app.projectId}/namespaces/${app.namespaceId}/applications`, app)
        .then((response) => {
          context.commit('SET_CREATED_APPLICATION', response.data)
        })
        .catch((error) => context.commit('SET_APPLICATION_ERROR', error.response.data.error.message))
    },
    DeleteApplication (context, app) {
      context.commit('SET_APPLICATION_ERROR', null)
      return axios.delete(`/projects/${app.projectId}/namespaces/${app.namespaceId}/applications/${app.id}`)
        .then(() => {})
        .catch((error) => context.commit('SET_APPLICATION_ERROR', error.response.data.error.message))
    },
    UpdateApplication (context, app) {
      context.commit('SET_APPLICATION_ERROR', null)
      return axios.patch(`/projects/${app.projectId}/namespaces/${app.namespaceId}/applications/${app.id}`, app)
      .then(() => {})
      .catch((error) => {
        context.commit('SET_APPLICATION_ERROR', error.response.data.error.message)
      })
    },
    GetApplicationDeploymentTemplate (context, query) {
      if (!query.background) {
        context.commit('SET_APPLICATION_LOADING', true)
      }      
      return axios.get(`/deploy-template?fileUrl=${query.fileUrl}`)
        .then((response) => {
          context.commit('SET_DEPLOY_TO_SYNPSE_APPLICATION', response.data)
          context.commit('SET_APPLICATION_LOADING', false)
        })
        .catch((error) => context.commit('SET_APPLICATION_ERROR', error.response.data.error.message))
    },
    RestartApplication (context, app) {
      context.commit('SET_APPLICATION_ERROR', null)
      return axios.post(`/projects/${app.projectId}/namespaces/${app.namespaceId}/applications/${app.id}/restart`, app)
      .then(() => {})
      .catch((error) => {
        context.commit('SET_APPLICATION_ERROR', error.response.data.error.message)
      })
    }
  }
}

export default application
