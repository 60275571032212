import axios from 'axios'

const role = {
  state: () => ({
    loading: false,
    roles: [],
    error: null
  }),

  getters: {
  },

  mutations: {
    SET_ROLES_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_ROLES_ERROR: (state, error) => {
      state.error = error
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
  },

  actions: {
    ListRoles (context, query) {
      context.commit('SET_ROLES_LOADING', true)
      return axios.get(`/projects/${query.projectId}/roles`)
        .then((response) => {
          context.commit('SET_ROLES', response.data)
          context.commit('SET_ROLES_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_ROLES_ERROR', error.response.data.error.message)
          context.commit('SET_ROLES_LOADING', false)
        })
    }
  }
}

export default role
