<template>
  <v-app-bar app dark dense clipped-left fixed>
    <v-app-bar-nav-icon
      class="hidden-lg-and-up"
      @click.native.stop="
        $store.commit('VUETIFY_DRAWER', !$store.state.user.drawer.isActive)
      "
    ></v-app-bar-nav-icon>
    <v-avatar class="mr-3" rounded size="28">
      <img alt="synpse-logo" src="/favicon-32x32.png" />
    </v-avatar>
    <v-toolbar-title>Synpse</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-btn-toggle v-if="currentNamespaceID">
      <v-btn
        small
        router
        :to="{
          name: 'applications',
          params: {
            project: currentProjectID,
            namespace: currentNamespaceID,
          },
        }"
      >
        Applications
        <!-- <v-icon>mdi-format-align-left</v-icon> -->
      </v-btn>
      <v-btn
        small
        router
        :to="{
          name: 'secrets',
          params: {
            project: currentProjectID,
            namespace: currentNamespaceID,
          },
        }"
      >
        Secrets
        <!-- <v-icon>mdi-format-align-center</v-icon> -->
      </v-btn>
    </v-btn-toggle>

    <v-spacer></v-spacer>

    <v-btn
      text
      :href="docs_href"
      target="_blank"
      rel="noopener"
      class="text--secondary"
    >
      Docs
    </v-btn>

    <v-btn text class="text--secondary" @click="openCLIHelperModal">
      CLI
    </v-btn>

    <!-- CLI Helper  -->
    <v-dialog v-model="cliHelperModal" max-width="400px">
      <install-cli-helper
        :dialog="this.cliHelperModal"
        @clicked="closeCLIHelperModal"
      ></install-cli-helper>
    </v-dialog>

    <!-- <v-menu class="items mr-3" bottom left offset-y origin="top right" open-on-hover transition="v-slide-y-transition">
      <template v-slot:activator="{ on: on }">
         <v-btn text v-on="on" class="white--text">
          How to...
        </v-btn>
      </template>
      <v-list dense>
          <span v-for="(item, i) in how_to_items" v-bind:key="i">
            <v-divider v-if="item.divider"></v-divider>
            <v-list-item v-else :key="i" :to="!item.target ? item.href : null" :href="item.target ? item.href : null" v-bind:router="!item.target" target="item.target">
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item>
          </span>
        </v-list>
    </v-menu> -->

    <v-menu
      class="items"
      bottom
      left
      offset-y
      origin="top right"
      transition="v-slide-y-transition"
    >
      <template v-slot:activator="{ on: on }">
        <v-btn icon light v-on="on" class="white--text">
          <v-avatar v-if="avatarUrl" size="32">
            <img
              :src="avatarUrl"
              :alt="$store.state.user.user.name"
            >
          </v-avatar>
          <v-icon v-else>settings</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <span v-for="(item, i) in dropdown_items" v-bind:key="i">
          <v-divider v-if="item.divider"></v-divider>
          <v-list-item
            v-else
            :key="i"
            :to="!item.target ? item.href : null"
            :href="item.target ? item.href : null"
            v-bind:router="!item.target"
            target="item.target"
          >
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </span>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import InstallCliHelper from './cli/InstallCli.vue';

export default {
  components: {
    InstallCliHelper,
  },
  data() {
    return {
      tabs: null,
      context: 'app context',
      loaded: false,
      docs_href: 'https://docs.synpse.net/',
      // how_to_items: [
      //   { text: 'Managing servers', href: '#', target: '_blank' },
      //   { text: 'Deploying applications', href: '#', target: '_blank' },
      // ],
      dropdown_items: [
        { icon: 'mdi-ssh', text: 'SSH Keys', href: '/ssh-keys' },
        { icon: 'vpn_key', text: 'Personal Access Keys', href: '/access-keys' },
        // { divider: true },
        // { text: 'Terms of service', href: 'https://synpse.net/tos', target: '_blank' },
        // { text: 'Privacy', href: 'https://synpse.net/privacy/', target: '_blank' },
        { divider: true },
        { text: 'Logout', href: '/logout' }
      ],
      cliHelperModal: false,
    }
  },

  computed: {
    selectedProject() {
      return this.$store.getters.getSelectedProject()
    },
    currentProjectID() {
      return this.$route.params.project
    },
    currentNamespaceID() {
      return this.$route.params.namespace
    },
    avatarUrl() {
      if (!this.$store.state.user.user) {
        return ''
      } 
      if (this.$store.state.user.user.providerName === 'github') {
        return `https://github.com/${this.$store.state.user.user.name}.png?size=36`
      }
      if (this.$store.state.user.user.providerName === 'google') {
        return this.$store.state.user.user?.info?.avatar_url
      }
      return ''
    },
  },

  mounted() {
    this.$store.dispatch('GetUser')
  },

  methods: {
    openCLIHelperModal() {
      this.cliHelperModal = true
    },
    closeCLIHelperModal() {
      this.cliHelperModal = false
    },
  }
}
</script>
